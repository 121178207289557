::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}


/* Track */

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}


/* Handle */

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}


/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #555;
}